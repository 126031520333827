<template>
  <div>
    <h2>Test Sequence</h2>

    <RawViewer
      :sequence="sequence"
      :resources="resources"
    />
  </div>
</template>

<script>
const RawViewer = () => import('./RawViewer')

export default {
  name: 'TestSequence',

  components: {
    RawViewer
  },

  data () {
    return {

      sequence: {
        settings: {
          duration: 35
        },
        scene: {
          id: 'scene-1'
        },
        layers: [
          {
            id: 'layer1',
            name: 'Layer One',
            modules: [
              {
                id: 'multi1',
                type: 'module',
                resource_id: 'lines-02',
                start_at: 14,
                end_at: 15,
                target: 'screen_centre|screen_left|screen_right|screen_left_far|screen_right_far'
              },
              {
                id: 'imageA1',
                type: 'module',
                resource_id: 'image-1',
                start_at: 5,
                end_at: 5.5,
                target: 'screen_right_far'
              }
            ],
            order: 0
          },

          {
            id: 'layer2',
            name: 'Layer Two',
            order: 1,
            modules: []
          },

          {
            id: 'layer3',
            name: 'Layer Three',
            modules: []
          },

          {
            id: 'layer4',
            name: 'Layer Four',
            modules: [
              {
                id: 'multi1',
                type: 'module',
                name: 'Multi 1',
                resource_id: 'lines-02',
                start_at: 12,
                end_at: 15,
                target: 'screen_centre|screen_left|screen_right|screen_left_far|screen_right_far'
              }
            ]
          },

          {
            id: 'layer5',
            name: 'Layer Five',
            modules: [
              {
                id: 'imageAlt1',
                type: 'module',
                resource_id: 'image-1',
                start_at: 9,
                end_at: 10,
                target: 'screen_left_far'
              }],
            order: 0
          }
        ]
      },

      resources: [
        {
          id: 'scene-1',
          type: 'scene',
          url: '/static/models/tests/warehouse/previz_demo_warehouse_280.glb',
          settings: {}
        },
        {
          id: 'image-1',
          type: 'image',
          url: '/static/textures/UV_Grid_Sm.jpg'
        },
        {
          id: 'image-2',
          type: 'image',
          url: 'https://s3.amazonaws.com/previz-cdn1/a/static/image-wide.png'
        },
        {
          id: 'image-3',
          type: 'image',
          url: 'https://s3.amazonaws.com/previz-cdn1/a/static/image-small.png'
        },
        {
          id: 'image-4',
          type: 'image',
          url: 'https://s3.amazonaws.com/previz-cdn1/a/static/image-grayscale.png'
        },
        {
          id: 'video-1',
          type: 'video',
          url: 'https://s3.amazonaws.com/previz-cdn1/a/static/test-light.mp4'
        },

        {
          id: 'video-2',
          type: 'video',
          url: 'https://s3.amazonaws.com/previz-cdn1/a/static/test-25fps.mp4'
        },

        {
          id: 'video-3',
          type: 'video',
          url: 'https://s3.amazonaws.com/previz-cdn1/a/static/test-30fps.mp4'
        },

        {
          id: 'lines-02',
          type: 'video',
          url: 'https://s3.amazonaws.com/previz-cdn1/a/static/videos/lines-02.mp4'
        },
        {
          id: 'loop-01',
          type: 'video',
          url: 'https://s3.amazonaws.com/previz-cdn1/a/static/videos/loop-01.mov'
        }
      ]

    }
  }
}
</script>
