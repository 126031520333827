<template>
  <div>
    <h2 v-if="validAsset">
      {{ asset.title }}
    </h2>
    <h2 v-else>
      Loading..
    </h2>

    <RawViewer
      v-if="hasData"
      :sequence="sequence"
      :resources="resources"
    />
  </div>
</template>
<script>

const RawViewer = () => import('./RawViewer')

export default {
  name: 'TestLiveSequence',

  components: {
    RawViewer
  },

  computed: {

    data () {
      return this.$store.getters['sequencer/getDataById'](this.asset.id)
    },

    sequence () {
      if (this.data !== null && this.data.sequence) return this.data.sequence
      return null
    },

    resources () {
      if (this.data !== null && this.data.resources) return this.data.resources
      return null
    },

    hasData () {
      return (this.sequence !== null && this.resources !== null)
    },

    assetId () {
      return this.$route.params.uuid
    },

    asset: function () {
      return this.$store.getters['assets/getAssetById'](this.assetId)
    },

    validAsset () {
      return this.asset !== null
    },

    url () {
      if (this.validAsset) return this.asset.url
      return null
    }

  },

  watch: {
    validAsset: {
      handler: function (value) {
        if (value === true) {
          this.loadSequence()
        }
      },
      immediate: true
    }
  },

  methods: {
    loadSequence () {
      if (this.validAsset) this.$store.dispatch('sequencer/loadFromUrl', { id: this.asset.id, url: this.url })
    }
  }
}
</script>
